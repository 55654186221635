import { Route } from 'vue-router';
import { isFirefox } from '@/node_modules/@osp/design-system/assets/js/utilities/browserDetectors';
import { useUxStore } from '~/@api/store/uxApi';
import { TrackingMixin } from '~/pages/tracking.mixin';

export default {
	mixins: [TrackingMixin],
	beforeRouteLeave(_to: Route, _from: Route, next) {
		if (process.client) {
			const { api: uxApi } = useUxStore(this.$store);

			// Avoid shifting of footer when page content disappears on route change
			uxApi.setFooterVisible(false);
			uxApi.setPageSwitchProgressStatus(true);

			// If in Firefox, cancel all running cancelable requests. Otherwise, Firefox will log errors for open fetches
			if (isFirefox()) {
				(window as any).osp?.http?.cancel('About to leave route');
			}
		}

		next();
	},
};
