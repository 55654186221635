import Vue from 'vue';
import { Route } from 'vue-router';
import { useTrackingStore } from '~/@api/store/trackingApi';
import { importRunTask, importPerformanceTracking } from '~/app-utils/dynamic-imports';
import { performanceTracking } from '~/app-utils/tracking.utils';

export const TrackingMixin = Vue.extend({
	beforeRouteEnter(to: Route, _from: Route, next): void {
		// Set page label before the route is entered
		const pageType =
			to.meta?.pageType ||
			to.meta?.spaType ||
			to.meta?.spaOtherId ||
			to.path?.split('/')?.slice(-1)?.pop() ||
			'UNKNOWN';

		performanceTracking.setLabel(undefined, pageType);

		next();
	},
	beforeRouteLeave(_to: Route, from: Route, next): void {
		// Only perform lux send when arrived to no leaving page already via SPA navigation
		// (For initial page calls we perform lux send when several other conditions are met, triggered by a vuex module)
		// Before route leave, send the RUM data, if it was not a device switch redirect
		if (!from.fullPath.includes('force-device')) {
			performanceTracking.luxSend(this.$lux, from.meta.pageType);
		}

		// Start a new LUX performance tracking session
		performanceTracking.init(this.$lux);

		next();
	},
	data: function () {
		return {
			lux: {
				sent: false,
			},
		};
	},
	watch: {
		// Watch CLS handling generation 2
		clsPreparationFinished: {
			immediate: true,
			handler(newValue) {
				if (process.server) return;

				const { api: trackingApi, state: trackingState } = useTrackingStore(this.$store);

				if (newValue && !trackingState.performance.reportedEvents.clsControlFinished) {
					trackingApi.registerEventClsFinished();
				}
			},
		},
		// Watch CLS handling generation 3
		'clsData.isFinished': {
			immediate: true,
			handler(newValue) {
				if (newValue) {
					this.handleClsFinished();
				}
			},
		},
		'$store.state.tracking.performance.unresolvedMarks'(newValue, oldValue) {
			if (oldValue > 0 && newValue === 0) {
				importRunTask().then(({ runTask }) => {
					runTask(() => useTrackingStore(this.$store).api.registerAllMarksFinished());
				});
			}
		},
		'$store.state.tracking.performance.readyToSend'(newValue, oldValue) {
			if (newValue && !oldValue && !this.lux.sent) {
				this.lux.sent = true;

				importPerformanceTracking().then(({ performanceTracking }) => {
					performanceTracking.loadingFinished(this.$lux);
					performanceTracking.luxSend(this.$lux, this.$route.meta.pageType);
				});
			}
		},
	},
	mounted() {
		performanceTracking.markLoadTime(this.$lux);

		performanceTracking.ensurePageLabel(
			this.$lux,
			this.$nuxt.context.from?.matched
				?.find((match) => match.meta?.pageType)
				?.meta?.pageType?.toString(),
		);

		if (!this.$nuxt.from) {
			importRunTask().then(({ runTask, yieldToMain }) => {
				runTask(async () => {
					const { api: trackingApi } = useTrackingStore(this.$store);

					trackingApi.reportSsrPerformanceMarks();
					await yieldToMain();
					trackingApi.registerEventRenderingFinished();
					await yieldToMain();
				});
			});
		}
	},
	methods: {
		handleClsFinished() {
			const { api: trackingApi, state: trackingState } = useTrackingStore(this.$store);

			if (process.client && !trackingState.performance.reportedEvents.clsControlFinished) {
				importRunTask().then(({ runTask }) => {
					runTask(() => trackingApi.registerEventClsFinished());
				});
			}
		},
	},
});
